<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" :isEnterprise="true" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육종류1 -->
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="LBL0002933"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육종류2 -->
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="LBL0002934"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육구분 -->
          <c-select
            type="search"
            itemText="codeName"
            codeGroupCd="EDU_CLASS_CD"
            itemValue="code"
            name="educationTypeCd"
            label="LBL0002935"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 법정교육 여부 -->
          <c-select
            :editable="editable"
            label="LBL0002800"
            itemText="codeName"
            itemValue="code"
            type="search"
            :comboItems="flagItems"
            name="legalEducationFlag"
            v-model="searchParam.legalEducationFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사용여부 -->
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 교육과정 목록 -->
    <c-table
      ref="table"
      title="LBL0002801"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- 등록 -->
            <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="linkClick" />
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-course",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            // 사업장
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            // 교육과정
            label: "LBL0002930",
            align: "left",
            style: 'width:200px',
            sortable: true,
            type: "link",
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            // 교육종류1
            label: "LBL0002933",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            // 교육종류2
            label: "LBL0002934",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            // 교육구분
            label: "LBL0002935",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "legalEducationFlagName",
            field: "legalEducationFlagName",
            // 법정교육여부
            label: "LBL0002800",
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationCycleName",
            field: "educationCycleName",
            // 법정교육주기
            label: "LBL0002802",
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
          {
            name: "estimatedEducationExpenses",
            field: "estimatedEducationExpenses",
            // 예상교육비
            label: "LBL0002803",
            style: 'width:100px',
            align: "center",
            type: 'cost',
            suffix: '원',
            sortable: true,
          },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            // 학습목적
            label: "LBL0002937",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            // 관련법규
            label: "LBL0002804",
            style: 'width:250px',
            align: "left",
            sortable: true,
          },
          {
            name: "eduQuestionFlag",
            field: "eduQuestionFlag",
            // 문제풀이
            label: "LBL0002805",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "useFlagName",
            field: "useFlagName",
            // 사용여부
            label: "LBLUSEFLAG",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        useFlag: 'Y',
        legalEducationFlag: null,
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      editable: true,
      listUrl: "",
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.edu.course.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "LBL0002806"; // 교육 과정 상세
      this.popupOptions.param = {
        eduCourseId: row ? row.eduCourseId : '',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/cc/educationCourseDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
